import styles from './index.module.scss'
import { Box, Button } from '@radix-ui/themes'
import water from 'assets/images/home/water.png'
import { useSetAtom } from 'jotai'
import { showWalletConnectDialogAtom } from '@/store'


export const Home = () => {
    const setShowWalletConnectDialog = useSetAtom(showWalletConnectDialogAtom)
    return (
        <div className={styles.home} style={{ backgroundImage: `url(${water})` }}>
            <p className={styles.title}>Welcome to Bitroot</p>
            <p className={styles.subTitle}>Powered by Bitroot</p>
            <div className={styles.container_wrap}>
                <div className={styles.container_body}>
                    <div className={styles.buttonWrap}>
                        <Button
                            className={styles.openWallet}
                            onClick={() => setShowWalletConnectDialog(true)}
                        >
                            <Box className={styles.openWalletText}>
                                Open Wallet
                            </Box>
                        </Button>
                    </div>
                    <div className={styles.openInfo}>
                        <span className={styles.openInfo_text}>Log into your wallet</span>
                    </div>
                </div>
            </div>
        </div>
    )
}