import { cn } from '@/utils/cn'
import { useParams } from 'react-router-dom'
import { useAtom, useAtomValue } from 'jotai'
import { addressAtom, chainAddressInfoAtom } from '@/store'
import { useMemo, useState } from 'react'
import { useRequest } from 'ahooks'
import { createOrder, getNormalizedBalances } from '@/request/api'
import { broadcastTx, getAddressPubKey, getBitcoinFees, getUtxos, hexToPSBTForUtxos, signMessage } from '@/utils'
import bigNumber from 'bignumber.js'
import { InputNumber, message, Slider } from 'antd'

export const BuyOrdersForm = ({ marketList }: { marketList: any[] }) => {
  let { pairSymbol = '' } = useParams()
  const [pairBaseSymbol, pairQuoteSymbol] = pairSymbol?.split('_')
  const [normalizeBanlance, setNormalizeBalance] = useState<any[]>([])
  const [price, setPrice] = useState<string>('0')
  const [amount, setAmount] = useState<string>('0')
  const [txInfo, setTxInfo] = useState<any>({})
  const [feeRate, setFeeRate] = useState(10)
  const [chainAddress] = useAtom(chainAddressInfoAtom)
  const [satoshisFee, setSatoshisFee] = useState<number>(0)

  const userAddress = useAtomValue(addressAtom)

  useRequest(async () => await getNormalizedBalances([userAddress]), {
    ready: !!userAddress,
    refreshDeps: [userAddress],
    onSuccess: (res: any) => {
      setNormalizeBalance(() => res ?? [])
    },
    onError: (e: any) => {
      message.error(e)
    }
  })

  const { data: feeOptions } = useRequest(async () => {
    const res = await getBitcoinFees()
    if (!res) return {}
    const keys = Object.keys(res).map((item) => Number(item)).filter((key, index) => index < 10)

    const _feeOptions = keys.reduce((prev, item) => {
      prev[item] = {
        fee: new bigNumber(res[item]).multipliedBy(1000).toFixed(2),
        baseFee: res[item].toFixed(2)
      }

      return prev
    }, {} as any)

    return _feeOptions
  }, {
    ready: true,
    pollingInterval: 10000
  })

  const { run } = useRequest(async (address, give_quantity, asset, get_quantity, get_asset, pubkey, fee_per_kb) => await createOrder(address, give_quantity, asset, get_quantity, get_asset, pubkey, fee_per_kb), {
    manual: true,
    debounceWait: 1000,
    onSuccess: async (res: any) => {
      setTxInfo(res)
      try {
        const utxos = await getUtxos(userAddress)
        const psbtHex = hexToPSBTForUtxos(res?.tx_hex, res?.inputs ?? utxos, {
          btc_in: res?.btc_in,
          btc_change: res?.btc_change,
          btc_out: res?.btc_out
        }) as string
        const signedPsbt = await signMessage(psbtHex) as string
        await broadcastTx(signedPsbt)

        message.success('Transaction Submitted! Please kindly wait for the block confirmation!')
      } catch (e: any) {
        message.error(e.message || e)
      }

    },
    onError: (e: any) => {
      message.error(e)
    }
  })

  const balance = useMemo(() => {
    if (pairQuoteSymbol === 'BTC') {
      const obj = chainAddress.find((item) => item.addr === userAddress) ?? {} as any

      const bn = new bigNumber(obj?.info?.balance ?? 0).plus(new bigNumber(obj?.info?.unconfirmedBalance ?? 0))
      return bn
    }

    let _pairQuoteSymbol = pairQuoteSymbol
    
    if(pairQuoteSymbol === 'tBRT') {
      _pairQuoteSymbol = 'BRT'
    }

    const info = normalizeBanlance?.find((item) => {

      return item.asset === _pairQuoteSymbol && item.address === userAddress
    }) ?? {}

    const bn = new bigNumber(info?.quantity ?? 0).div(10e7)
    return bn
  }, [userAddress, normalizeBanlance, chainAddress, pairQuoteSymbol])

  const marketprice = useMemo(() => {
    const _price = marketList.length > 0 ? marketList[marketList.length - 1][4] : 0

    const bn = new bigNumber(_price ?? 0)
    return bn
  }, [marketList])


  return (
    <div className={cn('flex flex-col flex-1 rounded-[15px] bg-[#101417] p-5')}>
      <div className="font-medium text-[18px] leading-[22px]">
        <div>Buy Orders</div>
      </div>
      <div className={cn('flex-1 mt-3.5 bg-black rounded-[5px]')}>
        <div className='flex items-center mt-[4px] py-[4px] justify-between px-[16px]'>
          <div className="eading-[22px] text-[14px] ">Balance</div>
          <div className="leading-[22px] text-right text-[12px] ">{balance.toFormat(6)} {pairQuoteSymbol}</div>
        </div>
        <div className='flex items-center mt-[4px] py-[4px] justify-between px-[16px]'>
          <div className="eading-[22px]  text-[14px]">Lowest ask price</div>
          <div className="leading-[22px] text-right text-[12px] ">{marketprice.div(10e7).toFormat()} {pairQuoteSymbol}</div>
        </div>
        <div className='flex items-center mt-[4px] py-[4px] justify-between px-[16px]'>
          <div className="eading-[22px]  text-[14px]">Obtainable</div>
          <div className="leading-[22px] text-right text-[12px] ">{marketprice && !!marketprice.toNumber() ? balance.div(marketprice).toFixed(6) : '0'} {pairBaseSymbol}</div>
        </div>
        <div className='flex items-center mt-[4px] py-[4px] justify-between px-[16px]'>
          <div className="eading-[22px]  text-[14px]">Price</div>
          <div className="leading-[22px] text-right text-[12px] flex items-center "> <InputNumber min='0' onChange={(e) => {
            setPrice(new bigNumber(e ?? '0').toString())
          }} value={price} style={{ width: '150px', textAlign: 'right', outline: 'none', borderRadius: '4px', padding: '0 10px', flexGrow: '1', height: '30px' }} />
            <div className='pl-[5px]'>{pairQuoteSymbol}</div></div>
        </div>
        <div className='flex items-center mt-[4px] py-[4px] justify-between px-[16px]'>
          <div className="eading-[22px] text-[14px] ">Amount</div>
          <div className="leading-[22px] text-right text-[12px] flex items-center ">
            <InputNumber min='0' onChange={(e) => setAmount(new bigNumber(e ?? 0).toString())} value={amount} style={{ width: '150px', textAlign: 'right', outline: 'none', borderRadius: '4px', padding: '0 10px', flexGrow: '1', height: '30px' }} />
            <div className='pl-[5px]'>{pairBaseSymbol}</div>
          </div>
        </div>
        <div className='flex items-center mt-[4px] py-[4px] justify-between px-[16px]'>
          <div className="eading-[22px]  text-[14px]">Total</div>
          <div className="leading-[22px] text-right text-[12px] ">{price && amount ? (new bigNumber(price ?? 0)).multipliedBy(Number(amount ?? 0)).toString() : '0'}</div>
        </div>
        <div className='flex items-center mt-[4px] py-[4px] justify-between px-[16px]'>
          <div className="eading-[22px]  text-[14px]">Bitcoin Fee</div>
          <Slider max={10} min={1} step={1} value={feeRate} className="w-[250px]" onChange={(e) => {
            setFeeRate(e)
            setSatoshisFee(feeOptions[11 - e].baseFee)
          }} />
        </div>
        <div className='flex items-center mt-[4px] py-[4px] justify-end px-[16px]'>
          {txInfo?.btc_fee && <span className='text-[10px]'> Transaction fee: {(txInfo.btc_fee ?? 0) / 100000000} BTC</span>}
        </div>
      </div>

      <div className="mt-[24px] py-1.5 px-2.5 text-center cursor-pointer bg-[#009046] rounded-[4px]" onClick={async () => {
        if (!userAddress) {
          return false
        }
        const total = new bigNumber(price ?? '0').multipliedBy(new bigNumber(amount ?? '0'))

        if(new bigNumber(price ?? '0').lt('0.00006')) {

          return message.error('price cannot be less than 0.00006')
        }
        if (total.gt(balance)) {
          message.error(`insufficient ${pairQuoteSymbol === 'XCP' ? 'tBRT' : pairQuoteSymbol} balance`)
          return
        }

        const pubkey = await getAddressPubKey(userAddress)
        const fee = Number(feeOptions[feeRate].fee)
        
        if(total.lt(0.00005)){
          return message.error('total cannot be less than 0.00005')
        }

        run(userAddress, total.multipliedBy(10e7).toNumber(), pairQuoteSymbol, new bigNumber(amount ?? '0').multipliedBy(10e7).toNumber(), pairBaseSymbol, pubkey, fee)
      }}>BUY({`${pairQuoteSymbol}-${pairBaseSymbol}`})</div>
      <div className="mt-3 text-[12px] font-medium leading-[1] text-center">Estimated wait: between {feeRate === 1 ? `1` : `1 ~ ${feeRate}`} blocks ({satoshisFee} Satoshis/Byte)</div>
    </div>
  )
}
