import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '@radix-ui/themes/styles.css';
import { Layout } from './components/Layout';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from './views/Home';
import { Market } from './views/Markets';
import { Balance } from './views/Balance';
import { OpenOrders } from './views/OpenOrders';
import { OrderHistory } from './views/OrderHistory';
import { History } from './views/History';
import { Theme } from '@radix-ui/themes';
import { MarketDetailPage } from './views/Markets/Detail';
import { Provider } from './components/provider';
import { Toaster } from 'react-hot-toast'
import { Provider as JotaiProvider } from 'jotai';
import { jotaiStore } from './store';
import { ConfigProvider } from 'antd';
import dark from 'antd/es/theme/themes/dark';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <>
    <Theme>
      <ConfigProvider theme={{
        algorithm: dark, // 开启暗色模式
        token: {
          colorPrimary: '#00FF8E',
        }
      }}>
        <JotaiProvider store={jotaiStore}>
          <Provider>
            <BrowserRouter>
              <Routes>
                <Route path='/' element={<Layout />}>
                  <Route index element={<Home />} />
                  <Route path='balance' element={<Balance />} />
                  <Route path='market' element={<Market />} />
                  <Route path='market/:pairSymbol' element={<MarketDetailPage />} />
                  <Route path='openOrders' element={<OpenOrders />} />
                  <Route path='orderHistory' element={<OrderHistory />} />
                  <Route path='history' element={<History />} />
                </Route>
              </Routes>
            </BrowserRouter>
          </Provider>
        </JotaiProvider>
      </ConfigProvider>

    </Theme >

    <Toaster toastOptions={{
      style: {
        width: '220px',
        maxWidth: '220px',
        background: 'white',
        borderRadius: '8px',
        boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.20)',
        color: 'black',
        margin: 0,
      },
    }} />
  </>
);
