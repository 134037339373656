import { Dialog } from "../ui/Dialog"
import styles from './index.module.scss'
import { useState } from "react"
import { Button } from 'antd'
import { useAtomValue } from "jotai"
import { addressAtom } from "@/store"
import { broadcastTx, getUtxos, hexToPSBTForUtxos, signMessage } from "@/utils"
import { message } from "antd"

export const SignTransactionDialog = ({ open, onClose }: { open: boolean, onClose: () => void }) => {
    const [value, setValue] = useState<string>('')
    const [signedTransaction, setSignTransaction] = useState<string>('')
    const userAddress = useAtomValue(addressAtom)

    const handleSigned = async (hexString: string, isBroacast?: boolean) => {
        try {
            const utxos = await getUtxos(userAddress)
            const psbtHex = hexToPSBTForUtxos(hexString, utxos) as string
            const signedPsbt = await signMessage(psbtHex) as string

            setSignTransaction(signedPsbt)

            if (isBroacast) {
                await broadcastTx(signedPsbt)
                message.success('Transaction Submitted! Please kindly wait for the block confirmation!')
            }
        } catch (e: any) {
            message.error(e.message || e)
        }
    }

    return <Dialog
        open={open}
        title={'Sign a Transaction'}
        onClose={() => onClose()}
        titleClass={styles.title}
        containerClass={styles.wrap}
    >
        <>
            <div className={styles.containerMobile}>
                <p className={styles.desc}>
                    Sign a transaction with address {userAddress}.
                </p>
                <div className={styles.mobileSignWrap}>
                    <p className={styles.signWrapMobile_label}>Unsigned hex transaction to Sign</p>
                    <div className={styles.textareaWrap}>
                        <textarea className={styles.textarea} value={value} onChange={(e) => setValue(e.target.value)} />
                    </div>
                </div>
                <div className={styles.mobileSignWrap}>
                    <p className={styles.signWrapMobile_label}>Signed transaction</p>
                    <div className={styles.textareaWrap}>
                        <textarea className={styles.textarea} value={signedTransaction} />
                    </div>
                </div>
            </div>
            <div className={styles.container}>
                <p className={styles.desc}>
                    Sign a transaction with address {userAddress}.
                </p>
                <div className={styles.signWrap}>
                    <p className={styles.signWrap_label}>Unsigned hex transaction to Sign</p>
                    <div className={styles.textareaWrap}>
                        <textarea className={styles.textarea} value={value} onChange={(e) => setValue(e.target.value)} />
                    </div>
                </div>
                <p className={styles.signDesc}>
                    Enter the message text to be signed.
                </p>
                <div className={styles.signWrap}>
                    <p className={styles.signWrap_label}>Signed transaction</p>
                    <div className={styles.textareaWrap}>
                        <textarea className={styles.textarea} value={signedTransaction} />
                    </div>
                </div>
                <p className={styles.signDesc}>
                    Enter the message text to be signed.
                </p>
            </div>
            <div className={'flex justify-end py-[16px] px-[32px]'}>
                <Button color="default" onClick={() => onClose()}>Close</Button>
                <Button
                    className="ml-[10px]"
                    type="primary"
                    onClick={() => handleSigned(value)}
                >
                    Sign
                </Button>
                <Button
                    className="ml-[10px]"
                    type="primary"
                    onClick={() => handleSigned(value, true)}
                >
                    Sign and Broandcast
                </Button>
            </div>
        </>
    </Dialog>
}