import { useCallback } from "react";
import logo from '@/assets/images/header/logo.svg'
import { jotaiStore, okxWalletProviderAtom } from "@/store";
import { OKXUniversalProvider } from "@okxconnect/universal-provider";

export const useConnectOkxWallet = () => {
  const handleInitOkxConnector = useCallback(async () => {
    const okxUniversalProvider = await OKXUniversalProvider.init({
      dappMetaData: {
        name: "Bitroot",
        icon: logo
      },
    })

    jotaiStore.set(okxWalletProviderAtom, okxUniversalProvider)
  }, [])

  return {
    handleInitOkxConnector,
  }
}