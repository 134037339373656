import { addressAtom } from "@/store"
import { Button, Form, Input, InputNumber, message, Slider } from "antd"
import { useAtomValue } from "jotai"
import { useState } from "react"
import { CreateSendParamsType, createSend } from "@/request/api"
import { useRequest } from "ahooks"
import { broadcastTx, getAddressPubKey, getBitcoinFees, getUtxos, hexToPSBTForUtxos, signMessage } from "@/utils"
import BigNumber from "bignumber.js"

export type TokenItemType = {
  tokenType: string | TokenType,
  balance: string
  formattedBalance: string
  asset: string
  address: string
}

export enum TokenType {
  BTC = 'BTC',
  BRT = 'tBRT',
}

export const SendDialog = ({ onClose, tokenInfo }: { onClose: () => void, tokenInfo: TokenItemType }) => {
  const [form] = Form.useForm()
  const userAddress = useAtomValue(addressAtom)
  const [transFee, setTransFee] = useState<number | null>()
  const [satoshisFee, setSatoshisFee] = useState<number>(0)
  const [fee, setFee] = useState<number>(0)

  const { data: feeOptions } = useRequest(async () => {
    const res = await getBitcoinFees()
    if (!res) return {}
    const keys = Object.keys(res).map((item) => Number(item)).filter((key, index) => index < 10)

    const _feeOptions = keys.reduce((prev, item) => {
      prev[item] = {
        fee: new BigNumber(res[item]).multipliedBy(1000).toFixed(2),
        baseFee: res[item].toFixed(2)
      }

      return prev
    }, {} as any)

    return _feeOptions
  }, {
    ready: true,
    pollingInterval: 10000
  })
  // tb1qsq9tjc7tfvxczs66xmxz5teyr9j7y4ej7qjquh
  const { run, loading: createLoading } = useRequest(async (params: CreateSendParamsType) => await createSend(params), {
    manual: true,
    debounceWait: 1000,
    onSuccess: async (res: any) => {
      setTransFee(res?.btc_fee)

      try {
        const utxos = await getUtxos(userAddress)
        const psbtHex = hexToPSBTForUtxos(res?.tx_hex, res?.inputs ?? utxos, {
          btc_in: res?.btc_in,
          btc_change: res?.btc_change,
          btc_out: res?.btc_out
        }) as string
        const signedPsbt = await signMessage(psbtHex) as string
        const rs = await broadcastTx(signedPsbt)

        console.log('rs-->', rs)

        message.success('Transaction Submitted! Please kindly wait for the block confirmation!')
        onClose?.()
      } catch (e: any) {
        console.log(e)
        message.error(e.message || e)
      }
    },
    onError: (e: any) => {
      message.error(e.message || e)
    }
  })

  const handleRequest = async (_quantity: number | string, _dest: string, fee: number) => {
    const pubkey = await getAddressPubKey(userAddress)

    const params: CreateSendParamsType = {
      source: userAddress,
      destination: _dest,
      quantity: Number(_quantity) * 100000000,
      asset: tokenInfo.tokenType === 'tBRT' ? 'XCP' : tokenInfo.tokenType,
      pubkey: [pubkey],
      fee_per_kb: fee,
    }

    run(params)
  }

  return <div className="bg-[#111318]">
    <p className="text-[12px]">Send {tokenInfo.tokenType} from your address {userAddress} to the address you enter below.</p>
    <p className="text-[12px] mt-[5px]"> You have {tokenInfo.formattedBalance} {tokenInfo.tokenType} available to send from this address.</p>
    <Form
      form={form}
      className="mt-[10px]"
      layout="vertical"
      initialValues={{
        feeRate: 10,
        dest: ''
      }}
      onValuesChange={(_, allVal) => {
        if (allVal?.feeRate) {
          setSatoshisFee(feeOptions[11 - allVal?.feeRate].baseFee)
        }
      }}
      onFinish={(value) => {
        const { quantity, dest, feeRate } = value
        const fee = Number(feeOptions[feeRate].fee)
        setFee(fee)
        handleRequest(quantity, dest, fee)
      }}>
      <Form.Item label={'Send To'} name={'dest'} rules={[{ required: true, }]}>
        <Input className="w-full" />
      </Form.Item>
      <Form.Item label={'Send Amount'} name={'quantity'} rules={[{ required: true, }]}>
        <InputNumber className="w-full" min={'0.00005'} max={tokenInfo.formattedBalance} />
      </Form.Item>
      <Form.Item layout="horizontal" label={'BitCoin Fee'} name={'feeRate'}>
        <Slider max={10} min={1} step={1} className="w-[250px]" />
      </Form.Item>
      <Form.Item dependencies={['feeRate']}>
        {
          ({ getFieldValue }) => {
            const feeRate = getFieldValue('feeRate')

            return (
              <div>
                {
                  transFee && <p className={'text-[#4A90E2] text-[14px]'}>
                    Transaction fee: {transFee / 100000000} BTC
                  </p>
                }
                {!!fee && <p className={'text-[#fff] text-[14px]'}>
                  Estimated wait: between {feeRate === 10 ? `1` : `1 ~ ${11 - feeRate}`} blocks ({satoshisFee}  Satoshis/Byte)
                </p>}
              </div>
            )
          }
        }
      </Form.Item>
      <Form.Item>
        <div className={'flex justify-end'}>
          <Button color="default" onClick={() => onClose()}>Cancel</Button>
          <Button
            className="ml-[10px]"
            loading={createLoading}
            type="primary"
            onClick={() => form.submit()}
          >
            Send
          </Button>
        </div>
      </Form.Item>
    </Form>
  </div>
}